import React from "react";
import styles from "./index.module.css";
import logo from "@/assets/images/logo-gray.svg";

function Footer() {

    return (
        <footer className={styles.footer}>
            <div className={styles.content}>
                <img className={styles.logo} src={logo} alt={"NextSide logo"}/>
                <div className={styles.contact}>
                    <span className={styles.contactText}>
                        대표 번호 : 0507-0177-6401
                        <span className={styles.dot}> • </span>
                        <br className={"br1172"}/>
                        문의 : help@nextside.pro
                    </span>
                    <span className={styles.copyright}>© 2023 NextSide All rights reserved.</span>
                </div>
            </div>
        </footer>
    );

}

export default React.memo(Footer);
