import React from "react";
import styles from "./index.module.css";
import icon from "@/assets/images/growth.svg";
import ProjectCard from "@/components/common/ProjectCard";
import card_icon_1 from "@/assets/images/project-card-icon-1.svg";
import card_icon_2 from "@/assets/images/project-card-icon-2.svg";
import card_icon_3 from "@/assets/images/project-card-icon-3.svg";
import {Swiper, SwiperSlide} from "swiper/react";

import "swiper/css";

function Main3() {

    const [swiperIndex, setSwiperIndex] = React.useState<number>(0);

    const projects = React.useCallback(() => [
        <ProjectCard
            key={0}
            imageSrc={card_icon_1}
            title={"☕ 카페 주문 앱 + 관리 웹"}
            time={"45일"}
            tags={["APP", "WEB", "SERVER"]}
        >
            반응형 웹페이지, API 서버<br/>
            Android, iOS 앱
        </ProjectCard>,
        <ProjectCard
            key={1}
            imageSrc={card_icon_1}
            title={"⛳ 골프장 예약 앱 + 관리 웹"}
            time={"45일"}
            tags={["APP", "WEB", "SERVER"]}
        >
            반응형 웹페이지, API 서버<br/>
            Android, iOS 앱
        </ProjectCard>,
        <ProjectCard
            key={2}
            imageSrc={card_icon_1}
            title={"🏃 피트니스 예약 앱 + 관리 웹"}
            time={"45일"}
            tags={["APP", "WEB", "SERVER"]}
        >
            반응형 웹페이지, API 서버<br/>
            Android, iOS 앱
        </ProjectCard>,
        <ProjectCard
            key={3}
            imageSrc={card_icon_2}
            title={"💼 소속사 백오피스 웹 + 직원 웹"}
            time={"45일"}
            tags={["WEB", "SERVER"]}
        >
            PC 웹페이지, API 서버<br/>
            직원용 모바일 웹페이지
        </ProjectCard>,
        <ProjectCard
            key={4}
            imageSrc={card_icon_2}
            title={"🛍 해외 쇼핑몰 구매대행 웹"}
            time={"45일"}
            tags={["WEB", "SERVER"]}
        >
            반응형 웹페이지, API 서버<br/>
            쇼핑몰 상품 크롤러
        </ProjectCard>,
        <ProjectCard
            key={5}
            imageSrc={card_icon_2}
            title={"🗺 지역 관광정보 제공 웹"}
            time={"30일"}
            tags={["WEB", "SERVER"]}
        >
            반응형 웹페이지, API 서버<br/>
            정부지원사업
        </ProjectCard>,
        <ProjectCard
            key={6}
            imageSrc={card_icon_1}
            title={"📊 가맹점 마일리지 관리 웹 + 앱"}
            time={"30일"}
            tags={["APP", "WEB", "SERVER"]}
        >
            반응형 웹페이지, API 서버<br/>
            Android, iOS 앱
        </ProjectCard>,
        <ProjectCard
            key={7}
            imageSrc={card_icon_3}
            title={"📚 인터넷 강의 동영상 시청 앱"}
            time={"30일"}
            tags={["APP", "SERVER"]}
        >
            Android, iOS 앱<br/>
            API 서버
        </ProjectCard>,
        <ProjectCard
            key={8}
            imageSrc={card_icon_1}
            title={"🍔 프랜차이즈 가맹점 웹 + 앱"}
            time={"30일"}
            tags={["APP", "WEB", "SERVER"]}
        >
            PC 웹페이지, API 서버<br/>
            가맹점주용 Android, iOS 앱
        </ProjectCard>,
    ], []);

    return (
        <section className={styles.section}>
            <div className={styles.sectionContent}>
                <img className={styles.titleIcon} src={icon} alt={""}/>
                <h2 className={styles.title}>
                    매번 새로운 중규모 프로젝트로 <br className={"br1172"}/>
                    부담없이 성장하세요
                </h2>
                <h3 className={styles.description}>
                    사이드 프로젝트처럼 재미있고 새롭습니다.<br/>
                    다른 프로분들과 함께 작업하며 <br className={"br768"}/>
                    다양한 프로젝트를 경험 해보세요.
                </h3>
                <article className={styles.article}>
                    <div className={styles.scroll}>
                        <div className={styles.scrollContainerFade}/>
                        <div className={styles.scrollContainer}>
                            {projects()}
                            {projects()}
                            {projects()}
                        </div>
                    </div>
                    <div className={styles.swiper}>
                        <Swiper
                            spaceBetween={12}
                            direction={"horizontal"}
                            onActiveIndexChange={(swiper) => setSwiperIndex(swiper.activeIndex)}
                        >
                            {projects().map((item, index) => (
                                <SwiperSlide key={index}>
                                    {item}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className={styles.swiperIndices}>
                        {projects().map((_, index) => (
                            <div key={index} className={`${styles.swiperIndex} ${index === swiperIndex ? styles["active"] : ""}`}/>
                        ))}
                    </div>
                </article>
            </div>
        </section>
    );

}

export default React.memo(Main3);
