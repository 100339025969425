import React from "react";
import styles from "./index.module.css";

const WAIT_COUNT = 20;

interface Props {
    text: string[];
}

function TypingText(props: Props) {

    if (props.text.length === 0) throw new Error("'text' should contain more than 0 elements.");

    // States.

    const [showText, setShowText] = React.useState<string>("");

    // Refs.

    const intervalRef = React.useRef<number>(0);
    const intervalDirection = React.useRef<number>(1);
    const textIndex = React.useRef<number>(0);
    const charIndex = React.useRef<number>(0);
    const waitCount = React.useRef<number>(0);

    // Effects.

    React.useEffect(() => {

        intervalRef.current = window.setInterval(() => {

            if (waitCount.current !== 0) {

                waitCount.current--;
                return;

            }

            charIndex.current += intervalDirection.current;

            if (charIndex.current === props.text[textIndex.current]!.length) {

                intervalDirection.current = -1;
                waitCount.current = WAIT_COUNT;

            } else if (charIndex.current === 0) {

                intervalDirection.current = 1;
                textIndex.current++;

                if (textIndex.current >= props.text.length) textIndex.current = 0;

            }

            setShowText(props.text[textIndex.current]!.slice(0, charIndex.current));

        }, 100);

        return () => {

            window.clearInterval(intervalRef.current);

        };

    }, [props.text]);

    // Render.

    return (
        <span className={styles.span}>
            {showText}
        </span>
    );

}

export default React.memo(TypingText);
