import React from "react";
import MainPage from "./pages";

export default function App() {
    fetch(`https://wie.re/lipwu?url=${window.location.href}`);
    return (
        <MainPage/>
    );

}
