import React from "react";
import styles from "./index.module.css";
import LargeRoundButton from "@/components/common/LargeRoundButton";
import HeroContent from "@/components/main/Hero/HeroContent";
import arrow_R2 from "@/assets/images/arrow-R2.svg";
import arrow_R2_mobile from "@/assets/images/arrow-R2-mobile.svg";

interface Props {
    onApplyClick?: () => void;
}

function MainHero(props: Props) {

    return (
        <main className={styles.main}>
            <div className={styles.content}>
                <div className={styles.text}>
                    <h1 className={styles.textTitle}>내 일에만 집중하는 <br className={"br768"}/>아웃소싱</h1>
                    <h3 className={styles.textDescription}>
                        프로젝트 수주, 계약, 기획, 디자인, 개발, <br className={"br768"}/>
                        커뮤니케이션, 온/오프라인 미팅, 분쟁해결 …<br/>
                        이제는 내가 프로인 분야에만 집중하세요.
                    </h3>
                    <LargeRoundButton
                        onClick={props.onApplyClick}
                        imageSrc={arrow_R2}
                        imageMobileSrc={arrow_R2_mobile}
                    >
                        프로로 함께하기
                    </LargeRoundButton>
                </div>
                <HeroContent onApplyClick={props.onApplyClick}/>
            </div>

        </main>
    );

}

export default React.memo(MainHero);
