import React from "react";
import styles from "./index.module.css";
import TypingText from "@/components/common/TypingText";
import arrow_r from "@/assets/images/arrow-R.svg";
import image1 from "@/assets/images/main1-image-1.svg";
import image2 from "@/assets/images/main1-image-2.svg";
import image1_mobile from "@/assets/images/main1-image-1-mobile.svg";
import image2_mobile from "@/assets/images/main1-image-2-mobile.svg";

const WORDS = ["프론트엔드", "백엔드"];

function Main1() {

    return (
        <section className={styles.section}>
            <div className={styles.sectionContent}>
                <h2 className={styles.title}>
                    <mark><TypingText text={WORDS}/>&nbsp;개발자</mark>
                    는&nbsp;
                    <mark><TypingText text={WORDS}/>&nbsp;개발</mark>
                    만
                </h2>
                <h2 className={styles.titleMobile}>
                    <div>
                        <mark><TypingText text={WORDS}/>&nbsp;개발자</mark>
                        는&nbsp;
                    </div>
                    <div>
                        <mark><TypingText text={WORDS}/>&nbsp;개발</mark>
                        만
                    </div>
                </h2>
                <article className={styles.contentBox}>
                    <h3 className={styles.contentBoxDescription}>
                        혼자하는 아웃소싱에는 여러 어려움이 있습니다.<br/>
                        이제는 사이드 프로젝트처럼 재미있고 편하게 일하세요.
                    </h3>
                    <div className={styles.contentBoxColumnContainer}>
                        <div className={styles.contentBoxColumnArrow}>
                            <img src={arrow_r} alt={""}/>
                        </div>
                        <div className={styles.contentBoxColumn}>
                            <img className={styles.contentBoxImage} src={image1} alt={""}/>
                            <img className={styles.contentBoxImageMobile} src={image1_mobile} alt={""}/>
                            <div className={styles.contentBoxText}>
                                <div className={styles.contentBoxColumnTitle}>혼자 아웃소싱을 진행할때</div>
                                <div className={styles.contentBoxColumnDescription}>
                                    프로젝트 수주, 계약, 기획, 디자인, 개발, <br/>
                                    커뮤니케이션, 온/오프라인 미팅, 분쟁해결 …<br/>
                                </div>
                            </div>
                        </div>
                        <div className={styles.contentBoxColumn}>
                            <img className={styles.contentBoxImage} src={image2} alt={""}/>
                            <img className={styles.contentBoxImageMobile} src={image2_mobile} alt={""}/>
                            <div className={styles.contentBoxText}>
                                <div className={styles.contentBoxColumnTitle}>NextSide와 함께 하면</div>
                                <div className={styles.contentBoxColumnDescription}>
                                    내가 프로인 분야에만 집중<br/>
                                    정해진 일정에 할당된 파트만 개발
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </section>
    );

}

export default React.memo(Main1);
