import React from "react";
import styles from "./index.module.css";
import main2_content from "@/assets/images/main2-content.svg";
import main2_content_mobile from "@/assets/images/main2-content-mobile.svg";

function Main2() {

    return (
        <section className={styles.section}>
            <div className={styles.sectionContent}>
                <h2 className={styles.title}>
                    프로젝트 수주, 클라이언트 응대, <br className={"br1172"}/>
                    온/오프라인 미팅<br/>
                    신경쓸 필요가 없습니다
                </h2>
                <h3 className={styles.description}>
                    내 일 할 시간을 잡아먹는 모든 것들, <br/>
                    NextSide가 매니징 합니다.
                </h3>
                <article>
                    <img className={styles.image} src={main2_content} alt={"main2_content"}/>
                    <img className={styles.imageMobile} src={main2_content_mobile} alt={"main2_content"}/>
                </article>
            </div>
        </section>
    );

}

export default React.memo(Main2);
