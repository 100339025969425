import React from "react";
import styles from "./index.module.css";
import { SubmitHandler, useForm } from "react-hook-form";
import close from "@/assets/images/modal-close-button.svg";

const KEY = "c8p6uDO7J5PRCx1NRjkFRVigIK802W7fWj5nyLRs/OR6Afyjxi6xGyyu4WHR+iaJwJvnPHlaTxLSY5CwVNscHpz1gCty5dGDWRJLkuXKnBCDY9z0/U6+OvCwDtqdOIjv21xK6UIOkpYQO/d3p+lvxNZQ2MTiRWQRNigPLtIJSdVXHAImBkIbA9N0qwL6QndEHGB3pJ93INLj0lPAuz1+yeFvyBdgfU7ndk7ld8dlN9UzUZ14+RCiD5vx0tWjv9vsfdH2K8xj9XCCSSQ2SopRt/FhDyeSTZVoQz/0IhrV5pD/W6BUhJlXrcvCbig7Eq/WsOxMDNqsRhvj4+RVfRbOXBr0eAVBvMd8kMo/CIpDc5T2GQqcY2pNWKpSq24am2j477dibhph7WeIupdN5PCKvHims0TynhhZhDoQRaXrirX8ZEKOu5uhSpxVE+g4PlvGlxHkELHe/8LZmGhdBMrx6rSiutZgFm4SZXbe/PSY6ivT9zk/BU1CxlABrIQwlMc5tKYCwfdOLaVedh6fEbZkVcvdWfDVkvwjpTGeZp5lkKpzU3vdBOrVZ59Qwprc5fjugZK88wfGszfwGQWb9rrq/cU2WBzNP1zkVRbphahruAEH77wDN4H/tNzjCeUhlJ8rXvtjhs7+82W9oemHyJ0F7PZ8zau/52BK753/gjkuviYh0xih89h8Ckj3E6VirUbyHtgfF9lBf8sequK+J9gbP6aI2ngkwzfSt4PNLBgAXl8zWl3zyp48GlMtNeIeapp7t+EbhCpBCslD8OWWyZLd/wkrDHrUlliJOJ+noud/zubw6LU2RCtEvHQgti9feAcS6s1nHIPl5b19cwmzQQc+LK768qo6bo4iwHpRF+YQy6jtYVSp1R9tQ/OafAHpJvCz70LBUwQB/L/WgmSNT/Wa2pxoaGmOdffUt7lvPaeqBSthA0NeIGjoGxWYYF5qeAhBYnFj5EKrz2AB/He002wgw9dneWb5039Jq8U7EGM7msc=";

interface Props {
    visible: boolean;
    onSuccess?: () => void;
    onFail?: () => void;
    onClose?: () => void;
}

interface FormInputs {
    name: string;
    phone: string;
    email: string;
    affiliation: string;
    portfolio: string;
    workTime: string;
    privacy: boolean;
    consultWithCall: boolean;
}

function MainApplyForm(props: Props) {

    // States.

    const { register, handleSubmit, watch, reset, trigger, formState: { isValid, isSubmitting} } = useForm<FormInputs>({ mode: "all" });

    const privacy = watch("privacy");
    const consultWithCall = watch("consultWithCall");

    // Callbacks.

    const onSubmit: SubmitHandler<FormInputs> = React.useCallback(async (data) => {

        let bodyData;

        if (data.consultWithCall) {

            bodyData = {
                name: "",
                phone_number: data.phone,
                email: "",
                team: "",
                portfolio: "",
                time: "",
                telephone_counseling: "예",
            };

        } else {

            bodyData = {
                name: data.name,
                phone_number: data.phone,
                email: data.email,
                team: data.affiliation,
                portfolio: data.portfolio,
                time: data.workTime,
                telephone_counseling: "아니요",
            };

        }

        try {

            const response = await fetch("https://api.nextside.pro/request", {
                method: "POST",
                body: JSON.stringify({
                    key: KEY,
                    data: bodyData,
                }),
            });

            if (!response.ok) {

                if (props.onFail) props.onFail();
                return;

            }

            reset();

            if (props.onSuccess) props.onSuccess();

        } catch (e) {

            if (props.onFail) props.onFail();

        }

    }, [props, reset]);

    const onClose = React.useCallback(() => {

        if (isSubmitting) return;
        if (props.onClose) props.onClose();

    }, [isSubmitting, props]);

    // Render.

    if (!props.visible) return null;

    return (
        <div className={styles.modalWrapper}>
            <div className={styles.modalContainer}>
                <form className={styles.modal} onSubmit={handleSubmit(onSubmit)} onClick={(e) => e.stopPropagation()}>
                    <img className={styles.modalCloseButton} src={close} alt={"x"} onClick={onClose}/>
                    <div className={styles.modalHeader}>
                        <div className={styles.modalHeaderTitle}>NextSide와 함께 해보세요</div>
                        <div className={styles.modalHeaderSubTitle}>
                            프로젝트의 프로가 되고싶다면, <br className={"br768"}/>
                            지금 바로 NextSide에 지원하세요
                        </div>
                    </div>
                    <div className={styles.modalBody}>
                        <div className={styles.inputContainer}>
                            <input
                                className={styles.input}
                                type={"text"}
                                placeholder={"이름"}
                                maxLength={32}
                                {...register("name", {
                                    required: true,
                                    disabled: consultWithCall || isSubmitting,
                                    shouldUnregister: true,
                                })}
                            />
                            <input
                                className={styles.input}
                                type={"email"}
                                placeholder={"이메일"}
                                maxLength={64}
                                {...register("email", {
                                    pattern: /.+@.+/,
                                    required: true,
                                    disabled: consultWithCall || isSubmitting,
                                    shouldUnregister: true,
                                })}
                            />
                            <input
                                className={styles.input}
                                type={"text"}
                                placeholder={"소속 (예: 아웃소싱 팀, 직장인)"}
                                maxLength={64}
                                {...register("affiliation", {
                                    required: true,
                                    disabled: consultWithCall || isSubmitting,
                                    shouldUnregister: true,
                                })}
                            />
                            <input
                                className={styles.input}
                                type={"text"}
                                placeholder={"포트폴리오 (URL 등)"}
                                maxLength={256}
                                {...register("portfolio", {
                                    required: true,
                                    disabled: consultWithCall || isSubmitting,
                                    shouldUnregister: true,
                                })}
                            />
                            <span className={styles.inputInfo}>파일 형식은 이메일(help@nextside.pro)로 전송해주세요.</span>
                            <input
                                className={styles.input}
                                type={"text"}
                                placeholder={"작업 가능 시간대 (예: 평일 10시 ~ 19시)"}
                                maxLength={64}
                                {...register("workTime", {
                                    required: true,
                                    disabled: consultWithCall || isSubmitting,
                                    shouldUnregister: true,
                                })}
                            />
                            <input
                                className={styles.input}
                                type={"text"}
                                placeholder={"휴대폰 번호 (- 제외) *"}
                                maxLength={11}
                                {...register("phone", {
                                    required: true,
                                    disabled: isSubmitting,
                                    shouldUnregister: true,
                                })}
                            />
                        </div>
                        <div className={styles.checkboxContainer}>
                            <label className={styles.checkboxItem}>
                                <input
                                    className={styles.checkbox}
                                    type={"checkbox"}
                                    {...register("privacy", {
                                        disabled: isSubmitting,
                                        shouldUnregister: true,
                                        onChange: () => trigger().then(),
                                    })}
                                />
                                <span className={styles.checkboxItemDescription}>개인정보 수집 및 이용 동의 (필수)</span>
                            </label>
                            <div className={styles.checkboxItemWithInfo}>
                                <label className={styles.checkboxItem}>
                                    <input
                                        className={styles.checkbox}
                                        type={"checkbox"}
                                        disabled={isSubmitting}
                                        {...register("consultWithCall", {
                                            disabled: isSubmitting,
                                            shouldUnregister: true,
                                            onChange: () => trigger().then(),
                                        })}
                                    />
                                    <span className={styles.checkboxItemDescription}>전화로 상담하기 (선택)</span>
                                </label>
                                <span className={styles.checkboxItemInfo}>운영팀이 기입하신 휴대폰 번호로 연락드립니다.</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.modalFooter}>
                        <button
                            type={"submit"}
                            className={styles.button}
                            disabled={!isValid || !privacy || isSubmitting}
                            onClick={handleSubmit(onSubmit)}
                        >
                            완료
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );

}

export default React.memo(MainApplyForm);
