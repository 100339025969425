import React from "react";
import styles from "./index.module.css";

interface Props {
    className?: string;
    imageSrc: string;
    title: string;
    time: string;
    children: React.ReactNode;
    tags: string[];
    overlay?: React.CSSProperties["background"];
    style?: React.CSSProperties;
    compact?: boolean;
}

function ProjectCard(props: Props) {

    return (
        <div className={`${styles.card} ${props.className ?? ""} ${props.compact ? styles.compact : ""}`} style={props.style}>
            <div className={styles.icon}>
                <img src={props.imageSrc} alt={""}/>
            </div>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.description}>
                <div>진행기간</div>
                <div>{props.time}</div>
            </div>
            <div className={styles.description}>
                <div>진행내용</div>
                <div>{props.children}</div>
            </div>
            <div className={styles.tagContainer}>
                {props.tags.map((tag, index) => (
                    <span key={index} className={styles.tag}>{tag}</span>
                ))}
            </div>
            {props.overlay && (
                <div className={styles.overlay} style={{ background: props.overlay }}/>
            )}
        </div>
    );

}

export default React.memo(ProjectCard);
