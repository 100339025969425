import React from "react";
import styles from "./index.module.css";
import Main6Faq from "@/components/main/Main6/Faq";

function Main6() {

    return (
        <section className={styles.section} id={"main-faq"}>
            <div className={styles.sectionContent}>
                <h2 className={styles.title}>FAQ</h2>
                <h3 className={styles.description}>NextSide 운영팀에 자주 묻는 질문</h3>
                <article className={styles.article}>
                    <Main6Faq title={"프로젝트 규모는 어느정도 인가요?"}>
                        예시 프로젝트와 유사한 규모의 프로젝트를 수주합니다.<br />
                        기간으로는 30~60일 내, 웹페이지 수로는 20~40페이지 내외 입니다.
                    </Main6Faq>
                    <Main6Faq title={"프로젝트를 진행하며 클라이언트와 직접 소통해야 되나요?"}>
                        아니요, 모든 내용은 운영팀이 소통합니다. 프로분들은 작업 내용을 운영팀에만 공유 해주시면 됩니다.<br />
                        온/오프라인 미팅 시에도 운영팀이 참석합니다.
                    </Main6Faq>
                    <Main6Faq title={"다른 소속이 있습니다. 겸업을 해도 되나요?"}>
                        네, 정해진 일정에 할당된 파트만 진행 해주시면 됩니다.
                    </Main6Faq>
                    <Main6Faq title={"늦은 시간에 작업을 해도 되나요?"}>
                        네, NextSide에서는 정해진 업무 시간이 없습니다.<br />
                        작업 가능 시간을 프로분들 별로 조율하여 매니징 합니다.
                    </Main6Faq>
                    <Main6Faq title={"파트별 공통 작업은 어떻게 진행하나요?"}>
                        프로젝트 진행 시 운영팀이 조율하여 항시 안내 드립니다.<br />
                        추가로 각 파트별 작업 내용 통합은 운영팀이 진행합니다.
                    </Main6Faq>
                    <Main6Faq title={"파트 분할, 비용 산정은 어떻게 진행되나요?"}>
                        작업 가능 시간, 프로분들의 의견을 반영하여 작업량과 투입 인원을 조율합니다.<br />
                        이러한 내용을 종합적으로 고려해 비용이 산정됩니다.
                    </Main6Faq>
                    <Main6Faq title={"비용은 언제, 어떻게 지급되나요?"}>
                        원천징수 혹은 세금계산서 발행 후 프로젝트 기간과 상황에 따라 일괄 혹은 분할 지급됩니다.
                    </Main6Faq>
                </article>
            </div>
        </section>
    );

}

export default React.memo(Main6);
