import React from "react";
import styles from "./index.module.css";
import logo from "@/assets/images/logo.svg";

interface Props {
    onApplyClick?: () => void;
}

function Header(props: Props) {

    return (
        <header className={styles.header}>
            <div className={styles.headerContent}>
                <img className={styles.logo} src={logo} alt={"NextSide"}/>
                <nav className={styles.buttons}>
                    {/*<a href="#" className={styles.button}>기능 소개</a>*/}
                    <a href="/#main-faq" className={styles.button}>FAQ</a>
                    <button className={styles.buttonBlue} onClick={props.onApplyClick}>함께하기</button>
                </nav>
            </div>
        </header>
    );

}

export default React.memo(Header);
