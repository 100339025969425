import React from "react";
import styles from "./index.module.css";
import ProjectCard from "@/components/common/ProjectCard";
import line from "@/assets/images/hero-shape-line.svg";
import star from "@/assets/images/hero-shape-star.svg";
import icon1 from "@/assets/images/hero-icon-1.svg";
import icon2 from "@/assets/images/hero-icon-2.svg";
import arrow_R2 from "@/assets/images/arrow-R2-white.svg";
import card_icon_1 from "@/assets/images/project-card-icon-1.svg";
import card_icon_2 from "@/assets/images/project-card-icon-2.svg";
import card_icon_3 from "@/assets/images/project-card-icon-3.svg";

interface Props {
    onApplyClick?: () => void;
}

function MainHeroContent(props: Props) {

    return (
        <div className={styles.container}>
            <img className={styles.line} src={line} alt={""}/>
            <img className={styles.star} src={star} alt={""}/>
            <ProjectCard
                className={styles.projectCard1}
                imageSrc={card_icon_1}
                title={"⛳ 골프장 예약 앱 + 관리 웹"}
                time={"45일"}
                tags={["APP", "WEB", "SERVER"]}
                overlay={"linear-gradient(90deg, transparent, var(--color-gray-5))"}
                compact
            >
                반응형 웹페이지, API 서버<br/>
                Android, iOS 앱
            </ProjectCard>
            <ProjectCard
                className={styles.projectCard2}
                imageSrc={card_icon_1}
                title={"☕ 카페 주문 앱 + 관리 웹"}
                time={"45일"}
                tags={["APP", "WEB", "SERVER"]}
            >
                반응형 웹페이지, API 서버<br/>
                Android, iOS 앱
            </ProjectCard>
            <ProjectCard
                className={styles.projectCard3}
                imageSrc={card_icon_1}
                title={"🛍 해외 쇼핑몰 구매대행 웹"}
                time={"45일"}
                tags={["WEB", "SERVER"]}
                overlay={"linear-gradient(90deg, var(--color-gray-5), transparent)"}
                compact
            >
                반응형 웹페이지, API 서버<br/>
                쇼핑몰 상품 크롤러
            </ProjectCard>
            <img className={styles.icon1} src={icon1} alt={""}/>
            <img className={styles.icon2} src={icon2} alt={""}/>
            <button
                className={styles.button}
                onClick={props.onApplyClick}
            >
                Project Start
                <img src={arrow_R2} alt={""}/>
            </button>
        </div>
    );

}

export default React.memo(MainHeroContent);
