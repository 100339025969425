import React from "react";
import styles from "./index.module.css";
import LargeRoundButton from "@/components/common/LargeRoundButton";
import computer from "@/assets/images/main7-background-computer.svg";

interface Props {
    onApplyClick?: () => void;
}

function Main7(props: Props) {

    return (
        <section className={styles.section}>
            <div className={styles.sectionContent}>
                <article className={styles.article}>
                    <div className={styles.square1}/>
                    <div className={styles.square2}/>
                    <img className={styles.computer} src={computer} alt={""}/>
                    <div className={styles.content}>
                        <h2 className={styles.title}>NextSide와 함께 해보세요</h2>
                        <h3 className={styles.description}>다양한 프로젝트로 같이 성장 해보세요.</h3>
                        <LargeRoundButton onClick={props.onApplyClick}>함께하기</LargeRoundButton>
                    </div>
                </article>
            </div>
        </section>
    );

}

export default React.memo(Main7);
