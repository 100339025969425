import React from "react";
import styles from "./index.module.css";
import StepCard from "@/components/common/StepCard";
import {Swiper, SwiperSlide} from "swiper/react";

function Main4() {

    const [swiperIndex, setSwiperIndex] = React.useState<number>(0);

    const steps = React.useCallback(() => [
        <StepCard
            key={1}
            step={1}
            title={"프로젝트 수주"}
            description={"NextSide 운영팀이 진행"}
        >
            · 새 프로젝트 탐색<br/>
            · 클라이언트 응대<br/>
            · 온/오프라인 미팅<br/>
            · 사전 필요내용 정리
        </StepCard>,
        <StepCard
            key={2}
            step={2}
            title={"프로젝트 내용 정리"}
            description={"NextSide 운영팀이 진행"}
        >
            · 기획서 검토, 수정<br/>
            · 구현 필요 내용 협의<br/>
            · 최적의 작업 방안 <br/>
            · 일정 조율, 파트 분할
        </StepCard>,
        <StepCard
            key={3}
            step={3}
            title={"프로젝트 진행"}
            description={"NextSide 프로가 진행"}
            highlightDescription
        >
            · 본인에게 할당된 작업 진행<br/>
            · 작업 내용 운영팀에 공유<br/>
            · NextSide 회의 참석
        </StepCard>,
        <StepCard
            key={4}
            step={4}
            title={"프로젝트 마감"}
            description={"NextSide 운영팀이 진행"}
        >
            · 산출물 전달 및 배포<br/>
            · 인수인계 문서 작성<br/>
            · 유지보수 협의<br/>
            · 프로젝트 대금 정산
        </StepCard>,
    ], []);

    return (
        <section className={styles.section}>
            <div className={styles.sectionContent}>
                <h2 className={styles.title}>
                    프리랜서, 직장인 상관없이 <br className={"br1172"}/>
                    내 일만 하면 되니까
                </h2>
                <h3 className={styles.description}>
                    본업이 있어도, 다른 일을 같이해도 괜찮습니다.<br/>
                    정해진 일정에 본인의 일만 하면 됩니다.
                </h3>
                <article className={styles.article}>
                    <div className={styles.steps}>
                        {steps()}
                    </div>
                    <div className={styles.swiper}>
                        <Swiper
                            spaceBetween={20}
                            direction={"horizontal"}
                            onActiveIndexChange={(swiper) => setSwiperIndex(swiper.activeIndex)}
                        >
                            {steps().map((item, index) => (
                                <SwiperSlide key={index}>
                                    {item}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className={styles.swiperIndices}>
                        {steps().map((_, index) => (
                            <div key={index} className={`${styles.swiperIndex} ${index === swiperIndex ? styles["active"] : ""}`}/>
                        ))}
                    </div>
                </article>
            </div>
        </section>
    );

}

export default React.memo(Main4);
