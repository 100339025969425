import React from "react";
import styles from "./index.module.css";
import arrow from "@/assets/images/arrow-dropdown.svg";

interface Props {
    title: string;
    children: React.ReactNode;
}

function Main6Faq(props: Props) {

    const [open, setOpen] = React.useState<boolean>(false);

    return (
        <div className={styles.container}>
            <div className={styles.title} onClick={() => setOpen(!open)}>
                <span className={styles.titleQ}>Q</span>
                <span className={styles.titleText}>
                    {props.title}
                </span>
                <button className={`${styles.titleButton} ${open ? styles["active"] : ""}`}>
                    <img src={arrow} alt={""}/>
                </button>
            </div>
            <div className={`${styles.contentWrapper} ${open ? styles["active"] : ""}`}>
                <div className={styles.content}>
                    {props.children}
                </div>
            </div>
        </div>
    );

}

export default React.memo(Main6Faq);
