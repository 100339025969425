import React from "react";
import MainHero from "@/components/main/Hero";
import Header from "@/components/common/Header";
import Main1 from "@/components/main/Main1";
import Main2 from "@/components/main/Main2";
import Main3 from "@/components/main/Main3";
import Main4 from "@/components/main/Main4";
import Main5 from "@/components/main/Main5";
import Footer from "@/components/common/Footer";
import Main6 from "@/components/main/Main6";
import Main7 from "@/components/main/Main7";
import MainApplyForm from "@/components/main/ApplyForm";

function MainPage() {

    const [formVisible, setFormVisible] = React.useState<boolean>(false);

    const onFormSuccess = React.useCallback(() => {

        alert("지원하였습니다.");
        setFormVisible(false);

    }, []);

    const onFormFail = React.useCallback(() => {

        alert("지원을 실패하였습니다.\n다시 시도해주세요.");
        setFormVisible(false);

    }, []);

    return (
        <React.Fragment>
            <Header onApplyClick={() => setFormVisible(true)}/>
            <MainHero onApplyClick={() => setFormVisible(true)}/>
            <Main1/>
            <Main2/>
            <Main3/>
            <Main4/>
            <Main5/>
            <Main6/>
            <Main7 onApplyClick={() => setFormVisible(true)}/>
            <Footer/>
            <MainApplyForm
                visible={formVisible}
                onClose={() => setFormVisible(false)}
                onSuccess={onFormSuccess}
            />
        </React.Fragment>
    );

}

export default React.memo(MainPage);
