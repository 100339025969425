import React from "react";
import styles from "./index.module.css";
import icon_1 from "@/assets/images/money-icon-1.svg";
import icon_2 from "@/assets/images/money-icon-2.svg";

function Main5() {

    return (
        <section className={styles.section}>
            <div className={styles.sectionContent}>
                <h2 className={styles.title}>
                    내 일에만 집중하고 <br className={"br768"}/>
                    수익을 가져가세요
                </h2>
                <h3 className={styles.description}>
                    다른 일도 하고, 포트폴리오도 만들며 성장하고, <br className={"br768"}/>
                    수익도 가져가세요.
                </h3>
                <h6 className={styles.warning}>(* 45일, 웹페이지 개발 4파트 분할 인당 기준)</h6>
                <article className={styles.moneyContainer}>
                    <div className={styles.money}>
                        <img className={styles.moneyImage} src={icon_1} alt={""}/>
                        <div className={styles.moneyText}>
                            <span>최소 월</span>
                            <span>
                                <b>200</b>
                                만원
                            </span>
                        </div>
                    </div>
                    <div className={styles.money}>
                        <img className={styles.moneyImage} src={icon_2} alt={""}/>
                        <div className={styles.moneyText}>
                            <span>프로젝트 당</span>
                            <span>
                                <b>300</b>
                                만원
                            </span>
                        </div>
                    </div>
                </article>
            </div>
        </section>
    );

}

export default React.memo(Main5);
