import React from "react";
import styles from "./index.module.css";

interface Props {
    children: React.ReactNode;
    className?: string;
    imageMobileSrc?: string;
    imageSrc?: string;
    onClick?: () => void;
}

function LargeRoundButton(props: Props) {

    return (
        <button className={`${styles.button} ${props.className ?? ""}`} onClick={props.onClick}>
            {props.children}
            {props.imageSrc && <img className={styles.image} src={props.imageSrc} alt={""}/>}
            {(props.imageMobileSrc || props.imageSrc) && <img className={styles.imageMobile} src={props.imageMobileSrc || props.imageSrc} alt={""}/>}
        </button>
    );

}

export default React.memo(LargeRoundButton);
