import React from "react";
import styles from "./index.module.css";

interface Props {
    step: number;
    title: string;
    description: string;
    highlightDescription?: boolean;
    children: React.ReactNode;
}

function StepCard(props: Props) {

    return (
        <div className={styles.card}>
            <div className={styles.step}>Step {props.step}</div>
            <div className={styles.title}>{props.title}</div>
            <div className={`${styles.description} ${props.highlightDescription ? styles["highlight"] : ""}`}>{props.description}</div>
            <div className={styles.content}>{props.children}</div>
        </div>
    );

}

export default React.memo(StepCard);
